<template>
    <div class="fully">
        <h1>Backendverwaltung</h1>
        <div>
            <span @click="expand =!expand"><v-icon name="plus"></v-icon></span>
            <div v-if="expand">
                <div>
                    <span>memberID  <input type="text" v-model="SelMember" placeholder="memberID" /> </span><span @click="getMemberData"><v-icon name="search"></v-icon></span>
                </div>

                <div v-if="validMember">
                    <hr />
                    <div class="col1">
                        <div>
                            <span class="lab">Roles</span>
                            <span class="role">{{SelectedMember.anzeigename}}</span>
                            <span class="lab">eMail</span>
                            <span class="role">{{SelectedMember.memberEmail}}</span>
                        </div>
                        <div v-for="(item,index ) in SelectedUserRoles" v-bind:key="index">
                            <input type="checkbox" v-model="item.check" value="item.value" />
                            {{item.value}}
                        </div>
                    </div>
                    <div class="col2"  @click="submitData">
                        <div><v-icon name="save" scale="2"></v-icon></div>
                        <div>{{updateinfo}}</div>
                    </div>
                    <!--{{SelectedUserRoles}}-->
                    <!--<select v-model="SingleRole">-->
                    <!--<div v-for="(item,index) in SelectedUserRoles" v-bind:key="index">
        <input type="checkbox" value="item" name="item" v-model=""/>{{item}}
    </div>-->
                    <!--</select> <v-icon name="save"></v-icon>-->
                    <div class="clear"></div>
                </div>
            </div>
            <hr />
        </div>
        <table class="fully">
            <tr>
                <th>ID</th><th>Name</th><th>eMail</th><th>Role</th><th>edit Role</th>
            </tr>
            <tr v-for="item in filterDataSet" v-bind:key="item.memberID">
                <td>{{item.memberID}} </td>
                <td>{{item.anzeigename}}</td>
                <td>{{item.memberEmail}}</td>
                <td>{{item.memberProperties.authtype}}</td>  
                <td @click="setDetail(item.memberID)"><v-icon name="edit"></v-icon></td>
            </tr>
        </table>
    </div>
</template>

<script>
    import { getMembers, getUserRoles, getMember, updateUserRoles } from '@/moduls/auth'
    export default {
        name: 'memberBackend',
        props: {

        },
        components: {
            //Kundenliste,
        },
        data() {
            return {
                datapool: [],
                UserRoles: [],
                SelectedUserRoles: [],
                SelectedMember: {},
                SingleRole: '',
                SelMember: '',
                working: false,
                filter: 'poweruser',
                filter1: 'admin',
                filter2: 'webtools',
                filter3: 'kundenadmin',
                filter4: 'finanzadmin',
                filter5: 'updateadmin',
                expand: false,
                validMember: false,
                updateinfo: '',
            }
        },
        methods: {
            async getKunden() {
                this.datapool = await getMembers()
            },
            async getUserRoleData() {
                this.UserRoles = await getUserRoles()
            },
            async getMemberData() {
                this.UserRoles = await getUserRoles()
                this.SelectedMember = await getMember(this.SelMember)
                this.validMember = this.SelectedMember != ''

                console.log(this.SelectedMember)
                console.log(this.UserRoles.length)
                this.SelectedUserRoles=[]
                for (var i = 0; i < this.UserRoles.length; i++) {
                    let checked = false
                    if (this.SelectedMember.memberProperties.authtype?.includes(this.UserRoles[i].toLowerCase())) {
                        checked = true
                    }
                    this.SelectedUserRoles.push({ value: this.UserRoles[i], check: checked })
                    //console.log(i)
                }
            },
            setDetail(memberID) {
                this.SelMember = memberID
                this.getMemberData()
            },
            async submitData() {
                let puti = {
                    items: this.SelectedUserRoles,
                    memberID: this.SelMember
                }
                console.log(JSON.stringify(puti))
                let res = await updateUserRoles(puti)

                this.updateinfo = res
                if (res=='') {
                    this.SelMember=''
                }
            }
        },
        created() {
            this.getKunden()
            //this.getUserRoleData()
        },
        computed: {
            filterDataSet: function () {
                return this.datapool.filter(post => {
                    var vm = this;
                    //console.log(post.memberProperties)
                    return post.memberProperties.authtype?.includes(vm.filter)
                        || post.memberProperties.authtype?.includes(vm.filter1)
                        || post.memberProperties.authtype?.includes(vm.filter2)
                        || post.memberProperties.authtype?.includes(vm.filter3)
                        || post.memberProperties.authtype?.includes(vm.filter4)
                        || post.memberProperties.authtype?.includes(vm.filter5)
                })
            }
        }
    };
</script>

<style scoped>
    .col1{
        float: left;
    }

    .col2{
        float:left;
        width: 100px;
        cursor: pointer;
    }

    .lab{
        padding-right:5px;
    }
    .role {
        padding-right: 5px;
        font-weight: bold;
    }
    .fully{
        width: 100%;
    }
</style>