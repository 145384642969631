<template>
    <div class="fully">
        <div id="nav">
            <button v-if="isAdminUser()" @click="selection=1">Backenduser Verwaltung</button>
            <button v-if="isAdminUser()" @click="selection=2">Mail Templates</button>
            <!--<button @click="selection=2" >Aktivierungskey zurücksetzen</button>
    <button @click="selection=3" >Produktkündigung</button>-->
        </div>
        <div class="clear"></div>
        <div class="content">
            <Backendverwaltung v-if="selection==1"></Backendverwaltung>
            <Mailverwaltung v-if="selection==2"></Mailverwaltung>
            <div v-if="selection>2"> Content {{selection}}</div>
            <div class="ph5">&nbsp;</div>
        </div>
        <div class="clear"></div>
    </div>
</template>

<script>

    import { isAdminUser, isUpdateUser} from '@/moduls/auth'

    import Backendverwaltung from '@/components/backendverwaltung.vue'
    import Mailverwaltung from '@/components/mailverwaltung.vue'

    export default {
        name: 'Service',
        props: {
            //suche: {}
        },
        components: {
            Backendverwaltung,
            Mailverwaltung
        },
        data() {
            return {
                selection: 0,
                working: false,
            }
        },
        mounted() {
            //console.log('mount '+isAdminUser())
            //console.log('mount ' + JSON.stringify(this.$route.params) + ' ' + this.suche)
        },
        methods: {
            isAdminUser() {
                return isAdminUser() || isUpdateUser()
                //return getUserInfo()?.role?.includes('Admin')
            },
            //isPowerUser() {
            //    return isPowerUser();
            //    //return getUserInfo()?.role?.includes('Poweruser')
            //},
        }
    };

</script>

<style scoped>
    .leftnav{
        float: left;
        width: 300px;
    }
    .contentbox{
        float:left;
        width: 100vh; /*  calc( 100vh - 300px);*/
    }

    button {
        background-color: #003a5c;
        border: none;
        color: white;
        padding: 0.8em;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 0.8em;
        cursor: pointer;
        width: 200px;
        float: left;
    }

    .fully{
        width: 100%;
    }
</style>